<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="integrate">
            <p>0</p>
            <p>会员积分</p>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #eee;
        .integrate{
            width: 92%;
            height: 120px;
            background-color: #f4ea2a;
            margin: 6px auto;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p{
                margin: 0;
                padding: 0;
                color: #fff;
                &:first-child{
                    font-size: 32px;
                    font-weight: bolder;
                }
                &:last-child{
                    font-size: 14px;
                }
            }
        }
    }
</style>